<template>
  <v-container>
    <v-card>
      <v-card-title>
        <div class="d-flex" style="gap: 8px;">
          <NavButton />
          <p class="mb-0">
            <span
                class="d-inline-block font-weight-bold text-uppercase text-h5 mr-2"
                style="transform: translateY(3px); word-break: break-word; "
            >
              {{ idProveedor ? `Rupes / ${provider.nombre}` : "Perfil de proveedor" }}
            </span>

            <v-tooltip v-if="tooltipPerfil" :color="tooltipPerfil.color" top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="tooltipPerfil.porcentaje" v-on="on" v-bind="attrs">
                  {{ tooltipPerfil.porcentaje }}%
                </v-chip>
                <v-icon  v-if="tooltipPerfil.icono" v-on="on" v-bind="attrs" :color="tooltipPerfil.color" size="30">
                  {{ tooltipPerfil.icono }}
                </v-icon>
              </template>
              <span>{{ tooltipPerfil.titulo }}</span>
            </v-tooltip>
          </p>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-alert v-if="provider?.id_estado_verificacion == 2" type="warning" border="left" prominent outlined>
          <v-row align="center">
            <v-col class="grow">
              <p class="mb-0  text-h6">
                Estimado usuario, debes de tomar en cuenta y corregir los siguientes apartados:
              </p>
              <div class="d-flex flex-column mt-4">
                <p v-for="(obs, index) in provider.observaciones_verificacion" :key="index" class="text--primary">
                  {{ index + 1 }}. {{ replaceFun(obs.observacion) }}
                </p>
              </div>
            </v-col>
            <v-col class="shrink">
              <v-btn color="primary" @click="modal_observacion = true">Resolver</v-btn>
            </v-col>
          </v-row>
        </v-alert>

        <!-- Información de perfil -->
        <InformacionPerfil
            :perfilCompleto="perfilCompleto"
            :progresoPerfil="progresoPerfil"
            :idProveedor="idProveedor"
            @on-save="cargarInformacionProveedor"
        />

        <!-- Opciones del menu de usuario -->
        <AccionesPerfil class="mt-4" :proveedor="provider">
          <template v-slot:item.actividades="{ item }">
            <ActividadProveedor
                :pulsating="pulsating"
                :label="item.label"
                :icon="item.icon"
                :id_proveedor="provider?.id"
                @update:recargar="recargar"
                ref="actividadProveedorRef"

            />
          </template>
        </AccionesPerfil>

        <ConfirmationModalComponent
            :is-open="modal_observacion"
            :is-loading="resolviendoObservacion"
            title="¿Desea marcar como resueltas las observaciones?"
            description="De confirmarse la siguiente acción, los cambios no serán reversibles."
            ok-text="Aceptar"
            @confirm="resolverObservaciones"
            @cancel="modal_observacion = false"
        />
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="modalRecordatorio"
      persistent
      max-width="700"
      min-height="700"
    >
      <v-card>
        <v-card-title> Recordatorio </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              Actualmente no tienes actividades registradas. Te invitamos a agregar tu actividad, giro o rubro para completar tu información
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="primary"
            @click="showRecordatorio"
          >
            Configurar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";
import InformacionPerfil from "./components/Perfil/InformacionPerfil.vue";
import AccionesPerfil from "./components/Perfil/AccionesPerfil.vue";
import NavButton from "@/components/utils/NavButton.vue";
import { mapActions, mapState } from "vuex";
import ActividadProveedor from "./components/ActividadProveedor.vue";

export default {
  name: "perfilProveedorView",
  components: {
    NavButton,
    AccionesPerfil,
    InformacionPerfil,
    ConfirmationModalComponent,
    ActividadProveedor,
  },
  data: () => ({
    pulsating: '',
    modalRecordatorio: false,
    proveedor: [],
    progresoPerfil: 0,
    perfilCompleto: true,
    modal_observacion: false,
    resolviendoObservacion: false,
  }),
  computed: {
    ...mapState("proveedores", ["provider"]),
    tooltipPerfil() {
      if (!this.progresoPerfil) return null;

      if (this.progresoPerfil === 100) {
        return ({
          color: 'cyanDinac',
          icono: 'mdi-check-decagram',
          titulo: 'Perfil completo',
        });
      }

      return ({
        porcentaje: Number(this.progresoPerfil).toFixed(0),
        titulo: 'Porcentaje de avance',
      });
    },
    idProveedor() {
      return this.$route.params.idProveedor;
    },
  },
  methods: {
    ...mapActions("proveedores", ["getProvider"]),
    // UI
    replaceFun(str) {
      return str ? str.replace(/<[^>]*>/g, "") : "";
    },
    // DATA
    async getProfilePercentage() {
      let response = await this.services.Proveedores.getProviderPercentage();

      if (response.status == 200) {
        this.progresoPerfil = response?.data.progreso;
        this.perfilCompleto = response?.data.perfil_completo;
      }
    },
    async resolverObservaciones() {
      try {
        this.resolviendoObservacion = true;
        const {status} = await this.services.Proveedores.revisionProveedor(this.provider.id);

        if ([200, 204].includes(status)) location.reload();
      } finally {
        this.resolviendoObservacion = false;
      }
    },
    cargarInformacionProveedor() {
      if (Number(this.idProveedor)) {
        return this.getProvider(Number(this.idProveedor));
      } else {
        return this.getProvider();
      }
    },
   async recargar() {
      this.proveedor.url_image = "";

      await this.cargarInformacionProveedor();

      if (this.idProveedor == null) {
        await this.getProfilePercentage();
      }
    },
    async existeActividadProveedor() {
      const {
        data: { actividades },
      } = await this.services.Proveedores.existeActividadProveedor();

      if (!actividades) {
        this.pulsating = "pulsating-chip";
        this.modalRecordatorio = true;
      }
    },
    showRecordatorio(){
      this.modalRecordatorio = false
      
      //console.log('first')
      this.$refs.actividadProveedorRef.showModal = true
    }
  },
  async created() {
    this.proveedor.url_image = "";

    await this.cargarInformacionProveedor();

    if (this.idProveedor == null) {
      await this.getProfilePercentage();
    }

    this.existeActividadProveedor();
  },
};
</script>
